import Vue from 'vue'
import router from "./router";
import App from './App.vue';
import store from './store/index.js';
import Vant, { Popup } from 'vant';
import 'vant/lib/index.css';
import axios from './http';
import {api , data} from './apis';
import { Lazyload } from 'vant';
import Loading from '@/components/loading.js';
import './assets/iconfont/iconfont.css';
// import vConsole from 'vconsole'
// Vue.prototype.$vConsole= new vConsole()

Vue.use(Loading)

Vue.use(Lazyload);
Vue.prototype.apis = api;
Vue.prototype.mydata = data;
Vue.prototype.$http = axios;

Vue.use(Vant);
Vue.use(Popup);
// Vue.prototype.toast = Toast;
Vue.config.productionTip = false

new Vue({
  axios,
  router,
  store,
  render: h => h(App),
}).$mount('#app')
