<template>
  <div id="app">
    <transition mode="out-in">
      <router-view />
    </transition>
    <div @click="toIndex" class="toIndex"><van-icon name="wap-home" /></div>
    <div @click="toLastPage" class="toLastPage">返回</div>
  </div>
</template>

<script>
window.hydra = window.croods = require("./assets/js/croods-1.3.3.js");
export default {
  name: "App",
  data() {
    return {};
  },
  created() {
    window.reGetUserInfo = function (token) {
      console(JSON.stringify("reGetUserInfo:" + token));
    };
    //禁止ios缩放
    window.onload = function () {
      document.addEventListener("touchstart", function (event) {
        if (event.touches.length > 1) {
          event.preventDefault();
        }
      });
      document.addEventListener("gesturestart", function (event) {
        event.preventDefault();
      });
    };
    this.getToken();
    this.$http({
      method: "POST",
      url: `${this.apis.getThemeTemplate}?orgId=${this.mydata.orgId}`,
      withCredentials: true,
    }).then((res) => {
      if (res.code == 200) {
        localStorage.setItem("sessionId", res.data.sessionId);
      }
    });
  },
  mounted() {
    // window.reGetUserInfo = function (token){
    //   alert(JSON.stringify("reGetUserInfo:"+token));
    // }
  },

  methods: {
    // 本地调试 调用这个方法  时token 换一下
    // getInfo() {
    //   this.$http({
    //     method: "post",
    //     url: `${this.apis.getUserInfo}?token=a0afb671c43f4e8d96a8ca92e2523377&orgId=${this.mydata.orgId}`,
    //   }).then((res) => {
    //     if (res.code == 200) {
    //       this.user = res.data;
    //       this.$store.commit("saveUser", res.data);
    //     }
    //   });
    // },
    getToken() {
      this.$store.commit("saveSuccessLogin", false);
      let that = this;
      croods.customPlugin({
        action: "UserPlugin.login",
        params: {},
        success: function (res) {
          console.log(res);
          that.$store.commit("saveSuccessLogin", true);
          that.$store.commit("saveToken", res.token);
          that
            .$http({
              method: "get",
              url: `${that.apis.getUserInfo}?token=${res.token}&orgId=${that.mydata.orgId}`, //${res.token} //
            })
            .then((res) => {
              if (res.code == 200) {
                that.$store.commit("saveUser", res.data);
                console.log(res);
              }
            });
        },
        fail: function (msg) {
          console.log(msg);
        },
      });
    },
    toIndex() {
      this.$router.push({ path: "/" });
    },
    toLastPage() {
      this.$router.go(-1);
    },
    // pluginClick: function (pluginName) {
    //   switch (pluginName) {
    //     case 'getToken':
    //       croods.customPlugin({
    //         action: 'UserPlugin.login',
    //         params: {},

    //         success: function (res) {
    //           alert(JSON.stringify(res))
    //         },
    //         fail: function (msg) {
    //         }
    //       })
    //       break;

    //     default:
    //       break
    //   }
    // }
  },
};
</script>

<style  scoped>
.toIndex {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 1px solid #ff9578;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  right: 5px;
  bottom: 140px;
  color: #969799;
  font-size: 19px;
}
.toLastPage {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 1px solid #969799;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  right: 5px;
  bottom: 90px;
  color: #969799;
  font-size: 12px;
}
</style>
